import React, { memo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { createCustomer, createUser } from "../api/CustomerApi";
import { getAllRole } from "../api/RoleApi";
import { CustomerAutoSearch } from "../api/CustomerApi";
import tw from "twin.macro";
import { currentRegister } from "../utility/Constant";
import toast from "react-hot-toast";
import { getBranchId } from "utility/HeaderConfig";
import signupBg from "images/login/signupBg.jpg";
import Divider from "../images/MyScheme/divider.png";
import { useCartContext } from "context/UseCartContext";
import SEO from "./headers/Seo";

const InputContainer = tw.div`my-5`;

function RegisterModal(props) {
  const [name, setName] = useState("");
  const [mobileno, setmobileno] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [password, setpassword] = useState("");

  const [invalidEmail, setinvalidEmail] = useState("");
  const [invalidMobile, setInvalidMobile] = useState("");

  const [emptyField, setEmptyField] = useState(false);
  const [availableEmail, setAvailableEmail] = useState(false);

  const [roleId, setRoleId] = useState(false);

  const [Loading, setLoading] = useState(false);
  const [availableMobile, setAvailableMobile] = useState(false);

  const { dispatch } = useCartContext();

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = () => {
    const filterData = {
      name: "Customer",
    };
    getAllRole(filterData).then((data) => {
      if (data.response.length > 0) {
        setRoleId(data.response[0].id);
      }
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    postAjaxRequest();
  };

  const postAjaxRequest = () => {
    //eslint-disable-next-line
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;

    //Invalid message validate //
    if (!reg.test(email) && email !== "") {
      setinvalidEmail(true);
      toast.error(" Invalid Email", {
        duration: 3000,
        position: "top-right",
      });
    } else {
      setinvalidEmail(false);
    }
    if (!mobileReg.test(mobileno) && mobileno !== "") {
      setInvalidMobile(true);
      setAvailableMobile(false);
    } else {
      setInvalidMobile(false);
    }

    // Empty Validate Field //
    const validateField = () => {
      if (name && password && reg.test(email) && mobileReg.test(mobileno) && address) {
        return true;
      } else {
        setEmptyField(true);
        setAvailableMobile(false);
        return false;
      }
    };

    if (validateField()) {
      const customerSearch = `mobile=${mobileno}&beforeCreatedAt=${currentRegister()}`;
      const response = CustomerAutoSearch(customerSearch);
      response.then(
        (data) => {
          if (data.response.length > 0) {
            setAvailableMobile(true);
            toast.error("Customer already available in this mobile number", {
              duration: 3000,
              position: "top-right",
            });
          } else {
            setAvailableMobile(false);
            createUserAccount();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const createUserAccount = () => {
    setLoading(true);
    const userDetils = {
      username: mobileno,
      password: password,     
      rolesList: [roleId],
      email: email !== "" ? email : null,
      branch: getBranchId(),
      employeeId : null

    };
    const responseData = createUser(JSON.stringify(userDetils));
    responseData.then(
      (data) => {
        createCustomerFunc();
      },
      (err) => {
        if (err.response) {
          let message = err.response.data.displayMessage;
          toast.error(message,{
            position:"top-right",
            duration : 2000
          })
          const index = message.split(" ");
          if (index[0] === "User") {
            setAvailableMobile(true);
          } else {
            setAvailableEmail(true);
          }
        }
        setLoading(false);
      }
    );
  };

  const createCustomerFunc = () => {
    setLoading(false);
    const customerDetails = {
      branchId: getBranchId(),
      name: name,
      mobile: mobileno,
      email: email,
      customerType: "CUSTOMER",
      enrollmentDate: currentRegister(),
      crmStatus: "ENGAGING",
      enquirySource: "WEB",
      religion: "NA",
      customerAddress: {
        address1: address,
      },
    };
    const responseData = createCustomer(JSON.stringify(customerDetails));
    responseData.then(
      (data) => {
        setLoading(false);
        setAvailableEmail(false);
        setAvailableMobile(false);
        toast.success("Customer And User Account Created Successfully...!", {
          duration: 3000,
          position: "top-right",
        });
        setTimeout(() => {
          dispatch({ type: "MODAL", payload: "login" });
        }, 1000);
      },
      (err) => {
        console.log(err);
        setAvailableEmail(false);
      }
    );
  };

  const close = () => {
    dispatch({ type: "MODAL", payload: false });
  };

  return (
    <>
    <SEO
        title='User Details - AMR Sumangalee Jewellers'
        description='Beginner friendly page for learning React Helmet.'
        name='Company name.'
        type='article' />
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      tw="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div
        tw="shadow-lg relative fixed flex bg-white outline-none focus:outline-none rounded-lg"
        className="col-11 col-sm-11 col-md-10 col-lg-8 col-xl-7  m-sm-0 "
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div className="d-none d-md-block col-sm-5">
          <img src={signupBg} className="w-100 h-100" tw="rounded-l-lg" alt="signup" />
        </div>
        <div className="col-12 col-md-7 ">
          <div tw="p-2 py-5  sm:p-5 xl:p-10">
            <div tw="uppercase tracking-wide text-xl sm:text-3xl text-black font-semibold text-center">
              Register As New Customer
              <img src={Divider} alt="heading-divider" tw="m-auto mt-2 w-1/4" />
            </div>
            <form>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-person" tw="text-xl"></i>
                  </div>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your Name"
                    className={`${
                      emptyField && name === "" ? "border border-danger" : ""
                    }`}
                  />
                  {emptyField && name === "" ? (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </InputContainer>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-phone" tw="text-xl"></i>
                  </div>
                  <input
                    type="text"
                    id="mobile"
                    value={mobileno}
                    onChange={(e) => {
                      setmobileno(e.target.value.trim());
                    }}
                    tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your Mobile Number "
                    className={`${
                      (emptyField && mobileno === "") ||
                      invalidMobile ||
                      availableMobile
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                  {(emptyField && mobileno === "") ||
                  invalidMobile ||
                  availableMobile ? (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  {emptyField && mobileno === "" ? (
                      <p tw="text-red-500 mt-1 mx-5 text-sm">
                        Enter Your Mobile Number
                      </p>
                    ) : (
                      ""
                    )}
               {invalidMobile && mobileno !== "" ? (
                      <p tw="text-red-500 mt-1 mb-0 mx-5 text-sm">
                        Mobile Number Field is Invalid
                      </p>
                    ) : (
                      ""
                    )}
                </div>
              </InputContainer>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-envelope" tw="text-xl"></i>
                  </div>
                  <input
                    type="email"
                    id="e-mail"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value.trim());
                    }}
                    tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your Email"
                    className={`${
                      (emptyField && email === "") || invalidEmail
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                  {emptyField && email === "" ? (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  {availableEmail ? (
                    <p tw="text-red-500 mt-1 mb-0 mx-5">
                      Email is Already Available. Please SignUp With Another
                      Email Id
                    </p>
                  ) : (
                    " "
                  )}
                </div>
              </InputContainer>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-file-lock2" tw="text-xl"></i>
                  </div>
                  {emptyField && password === "" ? (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <input
                    type="password"
                    id="password"
                    tw="focus:outline-none focus:border-custom-100
                      focus:ring-1   bg-gray-200 border border-gray-300 
                      text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your password"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    className={`${
                      emptyField && password === ""
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                </div>
              </InputContainer>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-geo-alt" tw="text-xl"></i>
                  </div>
                  <input
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => {
                      setaddress(e.target.value);
                    }}
                    tw="focus:outline-none focus:border-custom-100
                              focus:ring-1   bg-gray-200 border border-gray-300 
                              text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your Address"
                    className={`${
                      emptyField && address === "" ? "border border-danger" : ""
                    }`}
                  />
                  {emptyField && address === "" ? (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i className="bi bi-exclamation-circle text-danger"></i>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </InputContainer>
              <button
                type="submit"
                tw="mt-1 transition w-full text-white  bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                onClick={submitForm}
                disabled={Loading ? true : ""}
                className={`${Loading ? "bg-secondary" : ""} btn2`}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="mx-2">Loading . . .</span>
                  </>
                ) : (
                  <>Sign Up</>
                )}
              </button>
              <div tw="pt-5  text-center">
                Already Have Account ?
                <span onClick={() => dispatch({ type: "MODAL", payload: 'login' })}>
                  <span tw="text-blue-800 hover:text-custom-100 mx-2 text-sm tracking-wider font-bold cursor-pointer ">
                    Sign In
                  </span>
                </span>
              </div>
            </form>
          </div>
        </div>
        <div className="position-absolute top-0 end-0" onClick={close}>
          <i
            className="bi bi-x m-2"
            tw="text-3xl text-gray-600 cursor-pointer hover:text-custom-100"
          ></i>
        </div>
      </div>
    </motion.div>
    </>
  );
}

export default memo(RegisterModal);
